
import {get,post} from "@/axios";



export function getError_list(data) {
  return get('/api/logs/error_list',data);

}
export function getAction_list(data) {
  return get('/api/logs/action_list',data);

}




